import React, { useEffect, useState } from "react";
import apiStrapi, { baseURL } from "../../utils/api/apiStrapi";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { message, customConfirm } from "utils/notification";
import MaterialTable from "material-table";
import materialTable from "../../utils/materialTableUtils";
import "../../css/global.css";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { MenuProps, useStyles } from "../../utils/multiSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
const initialForm = {
  areasId: "",
  documentName: "",
  revision: "",
  creationDate: "",
  description: "",
  files: [],
};

export default function UploadNewDocument() {
  const [form, setForm] = useState({ ...initialForm });
  const [formDefault, setFormDefault] = useState();
  const [data, setData] = useState([]);
  const [areas, setAreas] = useState([]);
  const [file, setFile] = useState({ pdf: null });
  const [roles, setRoles] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [urlPfdUpdate, setUrlPfdUpdate] = useState({ url: null });
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const isAllSelected = roles.length > 0 && selected.length === roles.length;
  const user = useSelector(({ user }) => user.value);

  const handleChangeMulti = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === roles.length ? [] : roles);
      return;
    }
    setSelected(value);
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const loadData = async () => {
    try {
      const result = await apiStrapi.get("upload-new-documents?_limit=-1");
      if (result.data) {
        result.data.map((item) => {
          const areasId = item.areasId ? item.areasId.id : null;
          const areaName = item.areasId ? item.areasId.name : null;
          item.areasId = areasId;
          item.areaName = areaName;
          const userId = item.userId != null ? item.userId.id : null;
          const userName = item.userId != null ? item.userId.username : null;
          item.userName = userName;
          item.userId = userId;
        });
        setData(result.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (selected.length) {
        const listRoles = await apiStrapi.get("roles?_limit=-1");
        const array = [];
        listRoles.data.length &&
          listRoles.data.forEach((item) => {
            selected.forEach((element) => {
              if (element == item.name) array.push(item.id);
            });
          });
        let roles = array.filter((item, index) => {
          return array.indexOf(item) === index;
        });
        const model = {
          userId: user.id,
          areasId: form.areasId,
          documentName: form.documentName,
          revision: form.revision,
          creationDate: form.creationDate,
          description: form.description,
          roles: roles.toString(),
        };
        console.log("model", model);
        const data = new FormData();
        data.append("files.files", file.pdf);
        data.append("data", JSON.stringify(model));
        if (isUpdate) {
          if (file.pdf != null) {
            const position = form.files.length - 1;
            if (form.files[position] != undefined) {
              await apiStrapi.delete(`upload/files/${form.files[position].id}`);
            }
            ///////Registro de historial de actualizacion de documento//////////
            const odj = {
              userUpdatedId: user.id,
              userCreatorId: form.userId,
              documentNameOld: formDefault.documentName,
              documentNameNew: form.documentName,
            };
            const formData = new FormData();
            formData.append("files.files", file.pdf);
            formData.append("data", JSON.stringify(odj));
            await apiStrapi.post("audits", formData);
            ///////////////////////////////////////////////////////////////////////
          }
          const result = await apiStrapi.put(
            `upload-new-documents/${form.id}`,
            data
          );
          if (result.data) {
            message("Actualizado exitosamente", 1);
            handleReset();
          }
        } else {
          const users = await apiStrapi.get(`users?_limit=-1`);
          const result = await apiStrapi.post("upload-new-documents", data);
          if (result.data) {
            const areaName = result.data.areasId.name;
            message("Guardado exitosamente", 1);
            handleReset();
            console.log("users.data", users.data);
            if (users.data.length > 0) {
              const resultEmails = array.reduce((array, item) => {
                users.data.forEach((i) => {
                  if (i.roleId.id == item) array.push(i.email);
                });
                return array;
              }, []);
              const model = {
                email:
                  resultEmails.length > 0
                    ? resultEmails.toString()
                    : "test@gmail.com",
                nameDocument: form.documentName,
                url: result.data.id,
                area: areaName ? areaName.replace("#", "N°") : "",
              };
              const dataNew = JSON.stringify(model);
              await apiStrapi.post(`sendEmail/${dataNew}`);
            }
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadAreas = async () => {
    try {
      const result = await apiStrapi.get("areas?_limit=-1");
      const data = result.data;
      if (data.length) setAreas(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadRoles = async () => {
    try {
      const result = await apiStrapi.get("roles?_limit=-1");
      const data = result.data;
      if (data.length) {
        const newData = data.map((item) => {
          return item.name;
        });
        setRoles(newData);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleReset = () => {
    setForm({ ...initialForm });
    loadData();
    setFile({ pdf: null });
    setIsUpdate(false);
    setUrlPfdUpdate({ url: null });
    setSelected([]);
    setLoading(false);
  };

  const loadDataToForm = async (rowData) => {
    setIsUpdate(true);
    if (rowData) {
      const listRoles =
        rowData.roles &&
        rowData.roles.split(",").filter((item) => item != "," && item);
      const array = [];
      const result = await apiStrapi.get("roles?_limit=-1");
      if (result.data.length && listRoles != undefined) {
        result.data.forEach((item) => {
          listRoles.length &&
            listRoles.forEach((element) => {
              if (item.id == element) array.push(item.name);
            });
        });
      }
      const position = rowData.files.length - 1;
      if (rowData.files[position] != undefined) {
        setUrlPfdUpdate({
          url: `${baseURL}${rowData.files[position].url.slice(1)}`,
        });
      }
      setSelected(array);
      setForm(rowData);
      setFormDefault(rowData);
    }
  };

  const deleteRow = async (rowData) => {
    try {
      customConfirm(
        `Esta seguro que sea eliminar ${rowData.documentName}`
      ).then(async (confirm) => {
        if (confirm) {
          const result = await apiStrapi.delete(
            `upload-new-documents/${rowData.id}`
          );
          if (result.error) {
            message(result.message, 2);
          } else {
            loadData();
            message(`${rowData.documentName} Eliminado con exito`, 1);
          }
        }
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    loadData();
    loadAreas();
    loadRoles();
  }, []);

  return (
    <Card.Body>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col sm="3">
            <Form.Group>
              <p className="labelStyle">Area</p>
              <Form.Control
                as="select"
                name="areasId"
                value={form.areasId}
                onChange={handleChange}
              >
                <option value="">Seleccione</option>
                {areas.length &&
                  areas.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm="3">
            <Form.Group>
              <p className="labelStyle">Nombre del documento</p>
              <Form.Control
                type="text"
                name="documentName"
                value={form.documentName}
                onChange={handleChange}
                maxLength="20"
                placeholder="Nombre del documento"
                required
              />
            </Form.Group>
          </Col>
          <Col sm="3">
            <Form.Group>
              <p className="labelStyle">Revisión</p>
              <Form.Control
                type="text"
                name="revision"
                value={form.revision}
                onChange={handleChange}
                placeholder="Revisión"
                required
              />
            </Form.Group>
          </Col>
          <Col sm="3">
            <Form.Group>
              <p className="labelStyle">Fecha de creación</p>
              <Form.Control
                type="date"
                name="creationDate"
                value={form.creationDate}
                onChange={handleChange}
                placeholder="Fecha de creación"
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Form.Group>
              <p className="labelStyle">Descripción/Nota</p>
              <Form.Control
                as="textarea"
                name="description"
                value={form.description}
                onChange={handleChange}
                placeholder="Descripción/Nota"
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <FormControl className={classes.formControl}>
              <InputLabel id="mutiple-select-label">Asignado a</InputLabel>
              <Select
                labelId="mutiple-select-label"
                multiple
                value={selected}
                onChange={handleChangeMulti}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                required
              >
                <MenuItem
                  value="all"
                  classes={{
                    root: isAllSelected ? classes.selectedAll : "",
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={
                        selected.length > 0 && selected.length < roles.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Select All"
                  />
                </MenuItem>
                {roles.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    <ListItemIcon>
                      <Checkbox checked={selected.indexOf(option) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Col>
          <Col sm="6">
            <Form.Group>
              <p className="labelStyle">Adjuntar Documento PDF</p>
              <Form.Control
                type="file"
                name="file"
                accept="application/pdf"
                onChange={(e) => {
                  setFile({ pdf: e.target.files[0] });
                }}
                placeholder="Adjuntar Documento PDF"
                required={isUpdate ? false : true}
              />
            </Form.Group>
          </Col>
        </Row>
        {urlPfdUpdate.url != null && (
          <div className="text-center">
            <iframe
              id="inlineFrameExample"
              title="Inline Frame Example"
              width="450"
              height="350"
              src={urlPfdUpdate.url}
            ></iframe>
          </div>
        )}

        <Button type="submit">
          {loading ? "Cargando..." : isUpdate ? "Actualizar" : "Guardar"}
        </Button>
        {isUpdate && (
          <Button type="button" variant="danger" onClick={handleReset}>
            Cancelar
          </Button>
        )}
      </Form>
      <MaterialTable
        columns={[
          {
            title: "Id",
            field: "id",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Area",
            field: "areaName",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Nombre del documento",
            field: "documentName",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Revisión",
            field: "revision",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Fecha de creación",
            field: "creationDate",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Descripción",
            field: "description",
            headerStyle: { fontWeight: "bold" },
          },
        ]}
        options={materialTable.options}
        localization={materialTable.localization}
        data={data}
        title="Registro"
        actions={[
          {
            icon: () => <FontAwesomeIcon icon={faPen} />,
            tooltip: "Editar",
            onClick: (event, rowData) => {
              console.log(`rowData`, rowData);
              loadDataToForm(rowData);
            },
          },
          {
            icon: () => <FontAwesomeIcon icon={faTrash} />,
            tooltip: "Eliminar",
            onClick: (event, rowData) => deleteRow(rowData),
          },
        ]}
      />
    </Card.Body>
  );
}
