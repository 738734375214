import swal from 'sweetalert';

export const errorMessage = 'Error al procesar solicitud';

function emp(value) {
  return value == null || value.toString().trim().length === 0;
}

function getTitle(type) {
  switch (type) {
    case 1:
      return '¡Correcto!';

    case 2:
      return '¡Advertencia!';

    case 3:
      return '¡Error!';

    default:
      return '¡Información!';
  }
}

function getClassName(type) {
  switch (type) {
    case 1:
      return 'success';

    case 2:
      return 'warning';

    case 3:
      return 'error';

    default:
      return 'info';
  }
}

export function message(msg, type = '1', title = '', typemsg = 1) {
  let finalTitle = title;
  if (emp(finalTitle)) {
    finalTitle = getTitle(type);
  }

  if (typemsg === 1 || typemsg === 0) {
    const className = getClassName(type);
    console.log({
      title: finalTitle,
      text: msg,
      icon: className,
    });
    swal({
      title: finalTitle,
      text: msg,
      icon: className,
    });
  }
}

export async function customConfirm(text) {
  const result = await swal({
    text,
    buttons: { cancel: { visible: true, text: 'Cancelar' }, confirm: { text: 'Aceptar' } },
    title: '¡Advertencia!',
    icon: 'warning',
  });
  return result;
}
