import React, { useState, useEffect } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import "../../css/global.css";
import MaterialTable from "material-table";
import materialTable from "../../utils/materialTableUtils";
import { message, customConfirm } from "../../utils/notification";
import apiStrapi from "../../utils/api/apiStrapi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

const initialState = {
  name: "",
  description: "",
};

export default function Roles() {
  const [form, setForm] = useState({ ...initialState });
  const [isUpdate, setIsUpdate] = useState(false);
  const [list, setList] = useState([]);
  const [validated, setValidated] = useState(false);

  const loadRoles = async () => {
    try {
      const result = await apiStrapi.get("roles");
      const data = result.data;
      if (data) setList(data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formControl = e.currentTarget;
    const isValid = formControl.checkValidity() !== false;
    setValidated(true);
    if (isValid) {
      try {
        isUpdate
          ? (await apiStrapi.put(`roles/${form.id}`, form)) &&
            message("Actualizado con exito", 1)
          : (await apiStrapi.post("roles", form)) &&
            message("Guardado con exito", 1);
        handleReset();
      } catch (error) {
        console.log("Error: ", error.message);
      }
    }
  };

  const deleteRow = async (rowData) => {
    try {
      const confirm = await customConfirm(
        `Esta seguro que sea eliminar ${rowData.name}`
      );

      if (confirm) {
        console.log("confirm");
        const valid = await apiStrapi.get("upload-new-documents");
        const array = [];
        if (valid.data) {
          valid.data.forEach((item) => {
            const roles =
              item.roles != null &&
              item.roles.split("").filter((item) => item != "," && item);
            if (roles.length) {
              roles.forEach((i) => {
                if (i == rowData.id) array.push(item);
              });
            }
          });
        }
        if (!array.length > 0) {
          const result = await apiStrapi.delete(`roles/${rowData.id}`);
          if (result.status === 200)
            message(`Role ${rowData.name} eliminado correctamente`, 1);
          handleReset();
        } else {
          message(`El role esta asignado a ${array.length} documentos y no es posible Eliminarlo.`, 2);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleReset = () => {
    loadRoles();
    setIsUpdate(false);
    setForm({ ...initialState });
    setValidated(false);
  };

  useEffect(() => {
    loadRoles();
  }, []);

  return (
    <>
      <Card.Body>
        <Form
          noValidate={true}
          className={validated ? "was-validated" : ""}
          onSubmit={handleSubmit}
        >
          <Row>
            <Col sm="4">
              <Form.Group>
                <p className="labelStyle">Nombre *</p>
                <Form.Control
                  type="text"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                  placeholder="Nombre"
                  required
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group>
                <p className="labelStyle">Descripción *</p>
                <Form.Control
                  type="text"
                  name="description"
                  value={form.description}
                  onChange={handleChange}
                  placeholder="Descripción"
                />
              </Form.Group>
            </Col>
          </Row>

          <Button variant="primary" type="submit">
            {isUpdate ? "Actualizar" : "Guardar"}
          </Button>
          {isUpdate && (
            <Button variant="danger" onClick={handleReset}>
              Cancelar
            </Button>
          )}
        </Form>
        <MaterialTable
          columns={[
            {
              title: "Id",
              field: "id",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Nombre",
              field: "name",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Descripción",
              field: "description",
              headerStyle: { fontWeight: "bold" },
            },
          ]}
          options={materialTable.options}
          localization={materialTable.localization}
          data={list}
          title="Registro de roles"
          actions={[
            {
              icon: () => <FontAwesomeIcon icon={faPen} />,
              tooltip: "Editar",
              onClick: (event, rowData) => {
                console.log("rowData :>> ", rowData);
                setIsUpdate(true);
                setForm(rowData);
              },
            },
            {
              icon: () => <FontAwesomeIcon icon={faTrash} />,
              tooltip: "Eliminar",
              onClick: (event, rowData) => deleteRow(rowData),
            },
          ]}
        />
      </Card.Body>
    </>
  );
}
