import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export default store;

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const preloadedState = window.__PRELOADED_STATE__;
// const store = createStore(
//   rootReducer,
//   preloadedState,
//   composeEnhancers(applyMiddleware(thunk))
// );
// export default store;
// export default configureStore({
//   reducer: {
//     user: userSlice,
//   },
// });
