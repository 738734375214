import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";

import logo from "../../assets/img/LOGO_FALCON_BLANCO.svg";

import logoSidebar from "../../assets/img/bibliot.png";
import { useSelector } from "react-redux";
import apiStrapi from "../../utils/api/apiStrapi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCheck
} from "@fortawesome/free-solid-svg-icons";

var ps;

function Sidebar(props) {
  const user = useSelector(({ user }) => user.value);
  const [items, setItems] = useState([]);
  const sidebar = React.useRef();
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const menuData = async () => {
    const result = await apiStrapi.get("permissions");
    const data = result.data;
    const newData = [];
    if (props.routes.length) {
      props.routes.forEach((item) => {
        data.length &&
          data.forEach((element) => {
            if (
              user.roleId.id == element.roleId.id &&
              item.permissionId == element.namePermissionId.id
            ) {
              newData.push({ ...item, permissionnnId: element.id, roleId: user.roleId.id });
            }
          });
      });
      setItems(newData);
    }
  };

  React.useEffect(() => {
    menuData();
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  }, []);

  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo">
        <a className="simple-text logo-normal m-0">
          <div className="simple-text logo-normal text-center">
            <img src={logoSidebar} width={150} alt="react-logo" />
          </div>
        </a>
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <div className="text-white text-center mt-3">
          <FontAwesomeIcon className="mx-2" icon={faUserCheck} />
          {user?.firtsName} {user?.lastName}
        </div>
        <Nav>
          {items.length > 0 &&
            items.map((prop, key) => {
              return (
                <li
                  className={
                    activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                  }
                  key={key}
                >
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              );
            })}
        </Nav>
      </div>
      <div className="logo2">
        <a className="simple-text logo-normal m-0">
          <div className="simple-text logo-normal text-center">
            <img className="imageLogo2" src={logo} width={400} alt="react-logo" />
          </div>
        </a>
      </div>

    </div>
  );
}

export default Sidebar;
