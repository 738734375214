import React from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import logo from "../assets/img/wellcome.jpg";
import { useSelector } from "react-redux";

const Wellcome = () => {
  const user = useSelector(({ user }) => user.value);
  return (
    <Container className="login-container">
      <Form className="container text-center">
        <Row className="mb-4">
          <Col sm="12">
            <a href="/login" className="mb-3">
              <img src={logo} alt="Logo" />
            </a>
          </Col>
        </Row>
        <Row>
          <Col sm="12" style={{ color: "#175076" }}>
            <h3>{`Bienvenid@. ${user?.firtsName} ${user?.lastName}`}</h3>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default Wellcome;
