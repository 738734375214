import React, { useState, useEffect } from "react";
import { logoutRequest } from "../../redux/features/userSlice";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUserCog } from "@fortawesome/free-solid-svg-icons";
import apiStrapi from "../../utils/api/apiStrapi";
import { message, customConfirm } from "../../utils/notification";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { IconButton, InputAdornment, Input } from "@mui/material";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Popover, OverlayTrigger, Button, Modal, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import routes from "routes.js";

function Header(props) {
  const user = useSelector(({ user }) => user.value);
  const [validated, setValidated] = React.useState(false);
  const [form, setForm] = React.useState({
    currentPass: "",
    newPass: "",
    confirmNewPass: "",
  });
  const [isOpen, setIsOpen] = React.useState(false);
  const [show, setShow] = useState(false);
  const [dropdownUserOpen, setDropdownUserOpen] = useState(false);
  const toggleUser = () => setDropdownUserOpen(!dropdownUserOpen);
  const [color, setColor] = React.useState("transparent");
  const sidebarToggle = React.useRef();
  const [values, setValues] = useState({
    showPassword: false,
    showPassword2: false,
  });
  const [validPass, setValidPass] = useState(false);
  const [validPass2, setValidPass2] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("dark");
    }
    setIsOpen(!isOpen);
  };
  // const dropdownToggle = (e) => {
  //   setDropdownOpen(!dropdownOpen);
  // };
  const getBrand = () => {
    let brandName = " ";
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    return brandName;
  };
  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  };
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("dark");
    } else {
      setColor("transparent");
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowPassword2 = () => {
    setValues({ ...values, showPassword2: !values.showPassword2 });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const handleLogout = (e) => {
    e.preventDefault();
    try {
      dispatch(logoutRequest());
    } catch (error) {}
  };
  const handleModal = () => setShow(!show);
  React.useEffect(() => {
    window.addEventListener("resize", updateColor.bind(this));
  });
  React.useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    }
  }, [location]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>
        <Button className="popoverButton" onClick={handleModal}>
          Cambiar contraseña
        </Button>
      </Popover.Content>
    </Popover>
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formControl = event.currentTarget;
    const isValid = formControl.checkValidity() !== false;
    setValidated(true);
    if (isValid) {
      if (form.newPass === form.confirmNewPass) {
        const model = { ...user, password: form.newPass };
        const result = await apiStrapi.put(`users/${user.id}`, model);
        if (!result.data.length) {
          message("Contraseña actualizada exitosamente", 1);
          setValidated(false);
          setShow(false);
        } else {
          setForm({ ...form, currentPass: "" });
          message(result.message, 2);
        }
      } else {
        setForm({ ...form, newPass: "", confirmNewPass: "" });
        message("Contraseñas no coinciden", 2);
        setValidPass(false);
        setValidPass2(false);
      }
    } else {
      setValidPass(true);
      setValidPass2(true);
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <Navbar
      color={
        props.location.pathname.indexOf("full-screen-maps") !== -1
          ? "dark"
          : color
      }
      expand="lg"
      className={
        props.location.pathname.indexOf("full-screen-maps") !== -1
          ? "navbar-absolute fixed-top"
          : "navbar-absolute fixed-top " +
            (color === "transparent" ? "navbar-transparent " : "")
      }
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={sidebarToggle}
              className="navbar-toggler"
              onClick={() => openSidebar()}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <NavbarBrand>{getBrand() ? getBrand() : ""}</NavbarBrand>
        </div>
        <NavbarToggler onClick={toggle}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          {/* <form>
            <InputGroup className="no-border">
              <Input placeholder="Search..." />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <i className="nc-icon nc-zoom-split" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </form> */}
          <Nav navbar>
            {/* <button
              onClick={(e) => {
                e.preventDefault();
                try {
                  dispatch(logoutRequest());
                } catch (error) {}
              }}
            >
              Cerrar sesión
            </button> */}
            <button
              style={{ color: "white" }}
              onClick={handleLogout}
              className="btn btn-danger btn-sm mr-3"
              aria-disabled="true"
              title="Cerrar Sesión"
            >
              <FontAwesomeIcon icon={faSignOutAlt} size="sm" />
            </button>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              rootClose={true}
              overlay={popover}
            >
              <button
                style={{ background: "#253053", color: "white" }}
                className="btn btn-sm"
                aria-disabled="true"
                title="Configuracion"
              >
                <FontAwesomeIcon icon={faUserCog} size="sm" />
              </button>
            </OverlayTrigger>
            {/* <NavItem>
              <Link to="#pablo" className="nav-link btn-magnify">
                <i className="nc-icon nc-layout-11" />
                <p>
                  <span className="d-lg-none d-md-block">Stats</span>
                </p>
              </Link>
            </NavItem> */}
            {/* <Dropdown
              nav
              isOpen={dropdownOpen}
              toggle={(e) => dropdownToggle(e)}
            >
              <DropdownToggle caret nav>
                <i className="nc-icon nc-bell-55" />
                <p>
                  <span className="d-lg-none d-md-block">Some Actions</span>
                </p>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem tag="a">Action</DropdownItem>
                <DropdownItem tag="a">Another Action</DropdownItem>
                <DropdownItem tag="a">Something else here</DropdownItem>
              </DropdownMenu>
            </Dropdown> */}
            {/* <NavItem>
              <h1>config</h1>
            </NavItem> */}
          </Nav>
        </Collapse>
      </Container>
      <Modal show={show} onHide={handleModal} centered>
        <Modal.Header className="card-Header">
          <h5>Actualizar Contraseña</h5>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate={true} className={validated ? "was-validated" : ""}>
            <Form.Group>
              <Form.Label>Nueva contraseña *</Form.Label>
              <Input
                type={values.showPassword ? "text" : "password"}
                name="newPass"
                value={form.newPass}
                onChange={handleChange}
                required
                error={validPass}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Confirmar contraseña *</Form.Label>
              <Input
                type={values.showPassword2 ? "text" : "password"}
                name="confirmNewPass"
                value={form.confirmNewPass}
                onChange={handleChange}
                required
                error={validPass2}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleMouseDownPassword2}
                    >
                      {values.showPassword2 ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Actualizar
          </Button>
          <Button variant="danger" onClick={handleModal}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </Navbar>
  );
}

export default Header;
