import React, { useState, useEffect } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import MaterialTable from "material-table";
import materialTable from "../../utils/materialTableUtils";
import { message } from "../../utils/notification";
import "../../css/global.css";
import apiStrapi from "../../utils/api/apiStrapi";

export default function Permissions() {
  const [form, setForm] = useState({ roleId: "" });
  const [namePermissionsId, setNamePermissionsId] = useState([]);
  const [roles, setRoles] = useState([]);
  const [namePermissions, setNamePermissions] = useState([]);
  const [permissionsbyId, setPermissionsById] = useState([]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const namePermis = namePermissionsId.filter((item, index) => namePermissionsId.indexOf(item) === index)
      if (namePermis.length > 0) {
        const listDeletePermissions = []
        permissionsbyId.forEach(item => listDeletePermissions.push(apiStrapi.delete(`permissions/${item.id}`)))
        await Promise.all(listDeletePermissions)
        const array = []
        namePermis.forEach((item) => array.push(apiStrapi.post("permissions", {
          namePermissionId: item, roleId: form.roleId,
        })))
        await Promise.all(array)
        message("Permisos asignados con exito", 1);
      } else {
        const listDeletePermissions = []
        permissionsbyId.forEach(item => listDeletePermissions.push(apiStrapi.delete(`permissions/${item.id}`)))
        await Promise.all(listDeletePermissions)
        message("Permisos eliminados con exito", 1);
      }
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadRoles = async () => {
    try {
      const result = await apiStrapi.get("roles");
      result.data ? setRoles(result.data) : setRoles([]);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadNamePermissions = async () => {
    try {
      const result = await apiStrapi.get("name-permissions?_sort=name:ASC");
      if (result.data) setNamePermissions(result.data)
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadListPermissions = async (id) => {
    try {
      const result = await apiStrapi.get("permissions");
      if (result.data) {
        const data = result.data.filter((item) => {
          if (item.roleId.id == id) {
            const namePermissionId = item.namePermissionId ? item.namePermissionId.id : null;
            const namePermissionName = item.namePermissionId ? item.namePermissionId.name : null;
            const roleId = item.roleId ? item.roleId.id : null;
            const roleName = item.roleId ? item.roleId.name : null;
            item.namePermissionId = namePermissionId;
            item.namePermissionName = namePermissionName;
            item.roleId = roleId;
            item.roleName = roleName;
            return item
          }
        });

        const namesPermissionsId = data.map((item) => item.namePermissionId)

        setNamePermissionsId(namesPermissionsId);
        setPermissionsById(data);
      }
    } catch (error) {
      console.log("error.message :>> ", error.message);
    }
  };

  const handleChangePermissions = async (permissionsIds, rowData) => {
    let ids = permissionsIds.map((item) => item.id);
    if (rowData) {
      const namesPermissionsId = namePermissionsId.find(
        (id) => id === rowData.id
      );
      //Si existe se elimina del listado
      if (namesPermissionsId) {
        ids = namePermissionsId.filter((id) => id !== namesPermissionsId);
      } else {
        ids = namePermissionsId.concat([rowData.id]);
      }
    }
    setNamePermissionsId(ids);
  };

  useEffect(() => {
    loadRoles();
    loadNamePermissions();
  }, []);

  useEffect(() => {
    loadListPermissions(form.roleId);
  }, [form.roleId]);
  return (
    <>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col sm="4">
              <Form.Group>
                <p className="labelStyle">ROL</p>
                <Form.Control
                  as="select"
                  name="roleId"
                  value={form.roleId}
                  onChange={handleChange}
                  required
                >
                  <option value="">Seleccione</option>
                  {roles &&
                    roles.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Button type="submit">Guardar</Button>
        </Form>
        <MaterialTable
          columns={[
            {
              title: "Permiso",
              field: "name",
              headerStyle: { fontWeight: "bold" },
            },
          ]}
          localization={materialTable.localization}
          data={namePermissions}
          title="Permisos"
          options={{
            filtering: false,
            grouping: false,
            exportButton: true,
            actionsColumnIndex: -1,
            pageSize: 50,
            paging: true,
            pageSizeOptions: [50, 70, 90],
            emptyRowsWhenPaging: false,
            search: true,
            sorting: true,
            toolbar: true,
            padding: "dense",
            selection: true,
            selectionProps: (row) => {
              return {
                checked: namePermissionsId
                  ? namePermissionsId.includes(row.id)
                  : false,
              };
            },
          }}
          onSelectionChange={(rows, rowData) =>
            handleChangePermissions(rows, rowData)
          }
        />
      </Card.Body>
    </>
  );
}
