import axios from "axios";
import { token } from "utils/auth";
import configEnv from "../configEnv";

const options = {};
if (token) {
  options.headers = { Authorization: `bearer ${token}` };
}
export const baseURL = configEnv.strapiApiUrl;
const apiStrapi = axios.create({
  baseURL,
  timeout: 0,
  ...options,
});

//Redirect cuando el token se venza
apiStrapi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      // if (window.location.href.indexOf('/login') === -1) history.push('/login');
      if (window.location.href.indexOf("/login") === -1) {
        window.location.href = `/login?returnUrl=${window.location.pathname}`;
      }
      // history.replace('/login');
    }
    return Promise.reject(error);
  }
);

export default apiStrapi;
