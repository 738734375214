import React, { useEffect, useState } from "react";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import apiStrapi from "../../utils/api/apiStrapi";
import { baseURL } from "../../utils/api/apiStrapi";
import { Link } from "react-router-dom";

export default function ViewDocuments(props) {
  const id = props.match.params.id;
  const [file, setFile] = useState({ url: "" });
  const [data, setData] = useState();

  const loadDocuments = async (id) => {
    if (id) {
      try {
        const result = await apiStrapi.get(
          `upload-new-documents?_where[id]=${id}`
        );
        const data = result.data[0];

        if (data) {
          setData(data);
          const url = data.files[0] ? data.files[0].url.slice(1) : null;
          const urlPdf = `${baseURL}${url}`;
          setFile({ url: url != null ? urlPdf : null });
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  useEffect(() => {
    loadDocuments(id);
  }, [id]);
  
  if (data != undefined) {
    return (
      <div className="content">
        <div className="d-flex justify-content-between align-items-center">
          <h3>{data.documentName}</h3>
          <Link className="btn bg-primary" to="/admin/consultDocuments">
            Atras
          </Link>
        </div>
        {file.url && (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
            <div style={{ height: "750px" }}>
              <Viewer fileUrl={file.url} />
            </div>
          </Worker>
        )}
      </div>
    );
  } else {
    return <></>;
  }
}
