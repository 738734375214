import cookie from "js-cookie";

export const token = cookie.get("token") ? cookie.get("token") : null;

export const user = cookie.get("user") ? JSON.parse(cookie.get("user")) : null;
export const setToken = (token) => cookie.set("token", token);
export const setUser = (user) => cookie.set("user", user);

export const removeData = () => {
  cookie.remove("token");
  cookie.remove("user");
};

export const isInRole = (roleName) => {
  if (!user || !user.roles || user.roles.length === 0) {
    return false;
  }

  return user.roles.includes(roleName);
};

export const isInRoles = (roles = []) => {
  let exist = false;
  roles.forEach((roleName) => {
    exist = isInRole(roleName);
  });
  return exist;
};

export const userIsInRole = (user, roleName) => {
  if (!user || !user.roles || user.roles.length === 0) {
    return false;
  }

  return user.roles.includes(roleName);
};

export const userIsInRoles = (user, roles = []) => {
  let exist = false;
  roles.forEach((roleName) => {
    exist = userIsInRole(user, roleName);
  });
  return exist;
};
