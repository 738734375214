import React, { useState, useEffect } from "react";
import { Card, Tabs, Tab, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import apiStrapi from "../../utils/api/apiStrapi";
import UploadNewDocument from "./uploadNewDocument";
import UpdateDocument from "./updateDocument";

export default function CreateDocuments() {
  const [key, setKey] = useState("uploadNewDocument");
  const [loading, setLoading] = useState(true);
  const user = useSelector(({ user }) => user.value);

  /////////Permisos del usuario /////////////////////
  const [data1, setData1] = useState(false);
  const [data2, setData2] = useState(false);
  const uploadNewDocument = 7;
  const updateDocument = 8;
  ////////////////////////////////////////////////////

  const validatedPermissions = async () => {
    try {
      const result = await apiStrapi.get("permissions");
      if (result.data.length) {
        const result1 =
          result.data.length &&
          result.data
            .map(
              (item) =>
                item.namePermissionId.id == uploadNewDocument &&
                item.roleId.id == user.roleId.id &&
                item
            )
            .filter((item) => item != false);
        if (result1.length) setData1(true);

        const result2 =
          result.data.length &&
          result.data
            .map(
              (item) =>
                item.namePermissionId.id == updateDocument &&
                item.roleId.id == user.roleId.id &&
                item
            )
            .filter((item) => item != false);
        if (result2.length) setData2(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(`error.message`, error.message);
    }
  };

  useEffect(() => {
    validatedPermissions();
  }, []);

  return (
    <>
      <div className="content fluid">
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="grow" role="status">
              <span className="visually-hidden"></span>
            </Spinner>
          </div>
        ) : (
          <Card>
            <Tabs
              style={{
                fontSize: 15,
                margin: 2,
                backgroundColor: "",
                color: "#252422",
                fontWeight: "bold",
              }}
              className="m-3"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              unmountOnExit={true}
              mountOnEnter={true}
            >
              {data1 && (
                <Tab
                  eventKey="uploadNewDocument"
                  title="Nuevo Documento"
                  tabClassName="colorTab"
                >
                  <UploadNewDocument />
                </Tab>
              )}
              {/* {data2 && (
                <Tab
                  eventKey="updateDocument"
                  title="Actualizar Documento"
                  tabClassName="colorTab"
                >
                  <UpdateDocument />
                </Tab>
              )} */}
            </Tabs>
          </Card>
        )}
      </div>
    </>
  );
}
