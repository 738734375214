import Membership from "views/membership/home";
import Wellcome from "views/wellcome";
import CreateDocuments from "views/createDocuments/home";
import ConsultDocuments from "views/consultDocuments/consultDocuments";
import ViewDocuments from "views/consultDocuments/viewDocuments";
import Audits from "views/audits/audits";

var routes = [
  {
    path: "/users",
    name: "Usuarios",
    icon: "nc-icon nc-single-02",
    component: Membership,
    layout: "/admin",
    permissionId: 1,
  },
  {
    path: "/createDocuments",
    name: "Crear Documento",
    icon: "nc-icon nc-single-02",
    component: CreateDocuments,
    layout: "/admin",
    permissionId: 6,
  },
  {
    path: "/consultDocuments",
    name: "Consultar",
    icon: "nc-icon nc-single-02",
    component: ConsultDocuments,
    layout: "/admin",
    permissionId: 9,
  },
  {
    path: "/updateHistory",
    name: "Registros",
    icon: "nc-icon nc-single-02",
    component: Audits,
    layout: "/admin",
    permissionId: 10,
  },
  {
    path: "/wellcome",
    component: Wellcome,
    layout: "/admin",
  },
  {
    path: "/viewDocuments/:id",
    component: ViewDocuments,
    layout: "/admin",
  },
];
export default routes;
