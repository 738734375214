import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loginAsync } from "redux/features/userSlice";
import logo from "../assets/img/logo-login.jpg";

import "../assets/scss/login.scss";

const Login = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      dispatch(loginAsync(username, password));
    } catch (error) {}
  };

  return (
    <div className="login-containerrr">
      <div className="containerForm">
        <Form className="login-form text-center" onSubmit={handleSubmit}>
          <a href="/login" className="mb-3">
            <img src={logo} alt="Logo" />
          </a>
          <div className="text-left">
            <Row>
              <Col sm="12">
                <Form.Group>
                  <Form.Label htmlFor="username">Usuario</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    id="username"
                    value={username}
                    placeholder="Ingrese Usuario"
                    onChange={(event) => setUsername(event.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Form.Group>
                  <Form.Label htmlFor="password">Contraseña</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    id="password"
                    value={password}
                    placeholder="Ingrese Contraseña"
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
          <Row>
            <Col sm="12">
              <Button className="buttonLogin" type="submit">
                Iniciar sesión
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default Login;
