import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import materialTable from "../../utils/materialTableUtils";
import apiStrapi from "../../utils/api/apiStrapi";

export default function Audits() {
  const [data, setData] = useState([]);

  const loadData = async () => {
    try {
      const result = await apiStrapi.get("audits?_limit=-1");
      if (result.data) {
        result.data.map((item) => {
          const userCreatorName = item.userCreatorId != null ? item.userCreatorId.username : null;
          item.userCreatorName = userCreatorName;
          const userUpdatedName = item.userUpdatedId != null ? item.userUpdatedId.username : null;
          item.userUpdatedName = userUpdatedName;
        });
        setData(result.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="content">
      <MaterialTable
        columns={[
          {
            title: "Id",
            field: "id",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Nombre del antiguo documento",
            field: "documentNameOld",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Nombre del nuevo documento",
            field: "documentNameNew",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Usuario que lo creó",
            field: "userCreatorName",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Usuario que lo actualizó",
            field: "userUpdatedName",
            headerStyle: { fontWeight: "bold" },
          },
        ]}
        options={materialTable.options}
        localization={materialTable.localization}
        data={data}
        title="Historial de actualización de documentos"
      />
    </div>
  );
}
