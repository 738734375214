import React, { useEffect, useState } from "react";
import apiStrapi from "../../utils/api/apiStrapi";
import { Card, Accordion, Button } from "react-bootstrap";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { Link } from "react-router-dom";
import DefaultImage from "../../assets/img/pdf.png";
import { useSelector } from "react-redux";

export default function ConsultDocuments() {
  const [listDocuments, setListDocuments] = useState([]);
  const [listAreas, setListAreas] = useState([]);
  const user = useSelector(({ user }) => user.value);

  const loadAreas = async () => {
    try {
      const result = await apiStrapi.get("areas?_limit=-1");
      const data = result.data;
      return data;
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadDocuments = async () => {
    try {
      const result = await apiStrapi.get(
        `upload-new-documents?_limit=-1&_sort=id:desc`
      );
      const data = result.data;
      if (data.length) {
        const listDocuments = data.filter((item) => item.roles?.includes(user.roleId.id) && item)

        if (listDocuments.length > 0) {
          const areasd = listDocuments.map((item) => item.areasId.id);
          const list_areas = areasd.filter((item, index) => {
            return areasd.indexOf(item) === index;
          });
          const areas = await loadAreas();
          const areaList = [];
          if (areas.length > 0) {
            areas.forEach((item) => {
              list_areas.map((i) => i === item.id && areaList.push(item));
            });
          }
          setListAreas(areaList);
        }
        setListDocuments(listDocuments);
      }
    } catch (error) {
      console.log(error.message);
    }
  };


  useEffect(() => {
    loadAreas();
    loadDocuments();
  }, []);

  return (
    <div className="content">
      {listDocuments.length > 0 && (
        <Accordion>
          {listAreas.length > 0 &&
            listAreas.map((area, index) => (
              <Card>
                <Card.Header>
                  <h5 className="h5Modifi">{area.name}</h5>
                </Card.Header>
                <div className="container-cards">
                  {listDocuments.length > 0 &&
                    listDocuments.map(
                      (element) =>
                        element.areasId.id === area.id && (
                          <Card className="card-docs" key={element.id}>
                            <Link to={`viewDocuments/${element.id}`}>
                              <Card.Body>
                                <Card.Img variant="top" src={DefaultImage} />
                                <Card.Title className="mt-1 nameDcoument">
                                  {element.documentName}
                                </Card.Title>
                              </Card.Body>
                            </Link>
                          </Card>
                        )
                    )}
                </div>
              </Card>
            ))}
        </Accordion>
      )}
    </div>
  );
}
